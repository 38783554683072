<template>
  <navbar
    :position="isMenuOpen ? 'fixed' : 'relative'"
    type="green"
    menu-classes="ml-auto"
  >
    <router-link
      v-popover:popover1
      class="navbar-brand"
      to="/"
      @click="handleMenuClick"
    >
      <img src="img/icons/favicon.ico">
      SEUS CONSULT
    </router-link>

    <template slot="navbar-menu">
      <li class="nav-item">
        <router-link
          class="nav-link"
          to="/about-us"
          @click="handleMenuClick"
        >
          <p>About Us</p>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link"
          to="/Virtues"
          @click="handleMenuClick"
        >
          <p>Virtues</p>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link"
          to="/green-solutions"
          @click="handleMenuClick"
        >
          <p>Eco Solutions</p>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link"
          to="/Profile"
          @click="handleMenuClick"
        >
          <p>Team</p>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link"
          to="/Portfolio"
          @click="handleMenuClick"
        >
          <p>Portfolio</p>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link"
          to="/Projects"
          @click="handleMenuClick"
        >
          <p>Projects</p>
        </router-link>
      </li>
      
      <li class="nav-item">
        <router-link
          class="nav-link"
          to="/contact"
          @click="handleMenuClick"
        >
          <p>Contact Us</p>
        </router-link>
      </li>
    </template>
  </navbar>
</template>

<script>
import { Navbar } from '@/components';
import { Popover } from 'element-ui';

export default {
  name: 'MainNavbar',
  components: {
    Navbar,
    [Popover.name]: Popover,
  },
  data() {
    return {
      isMenuOpen: false, // Track menu open state
    };
  },
  mounted() {
    // Listen to a toggle event on the Navbar component
    this.$on('toggle', this.toggleMenu);
  },
  methods: {
    handleMenuClick() {
      // Close the menu and reset the navbar position
      this.isMenuOpen = false;
      this.$emit('close'); // Notify the Navbar component to close
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
  },
};
</script>

<style scoped>
</style>
