import Vue from "vue";
import App from "./App.vue";
import router from "./router";

// plugins
import NowUIKit from './plugins/now-ui-kit';

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';

/* import specific icons */
import { faEye, faBullseye } from '@fortawesome/free-solid-svg-icons';
import { faLeanpub } from '@fortawesome/free-brands-svg-icons';
import { faHandshake } from '@fortawesome/free-regular-svg-icons';

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

/* add only specific icons to the library */
library.add(faEye, faBullseye, faLeanpub, faHandshake);

/* register the font awesome icon component */
Vue.component('FontAwesomeIcon', FontAwesomeIcon);

Vue.config.productionTip = false;
Vue.use(NowUIKit);

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
