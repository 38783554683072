<template>
  <footer
    class="footer"
    :class="{ [`footer-${type}`]: type }"
    :data-background-color="backgroundColor"
  >
    <div class="container">
      <nav>
        <ul>
          <li>
            All rights reserved, &copy; {{ year }}
          </li>
        </ul>
      </nav>
      <div class="copyright">
        Designed by
        <a
          href="https:///yuricomputers.com"
          target="_blank"
          rel="noopener"
        >YURI</a>
      </div>
    </div>
  </footer>
</template>
<script>
/* eslint-disable */
export default {
    props: {
      backgroundColor: String,
      type: String
    },
    data() {
      return {
        year: new Date().getFullYear()
      };
    }
  };
</script>
<style></style>
