import Vue from 'vue';
import VueRouter from 'vue-router';

// Eagerly load frequently used layout components
import MainNavbar from './layout/MainNavbar.vue';
import MainFooter from './layout/MainFooter.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Index',
    components: {
      default: () => import(/* webpackChunkName: "Index" */ './pages/Index.vue'),
      header: MainNavbar,
      footer: MainFooter,
    },
  },
  {
    path: '/profile',
    name: 'Profile',
    components: {
      default: () => import(/* webpackChunkName: "Profile" */ './pages/Profile.vue'),
      header: MainNavbar,
      footer: MainFooter,
    },
  },
  {
    path: '/projects',
    name: 'Projects',
    components: {
      default: () => import(/* webpackChunkName: "Projects" */ './pages/Projects.vue'),
      header: MainNavbar,
      footer: MainFooter,
    },
  },
  {
    path: '/virtues',
    name: 'Virtues',
    components: {
      default: () => import(/* webpackChunkName: "Virtues" */ './pages/Virtues.vue'),
      header: MainNavbar,
      footer: MainFooter,
    },
  },
  {
    path: '/portfolio',
    name: 'Portfolio',
    components: {
      default: () => import(/* webpackChunkName: "Portfolio" */ './pages/Portfolio.vue'),
      header: MainNavbar,
      footer: MainFooter,
    },
  },
  {
    path: '/about-us',
    name: 'About',
    components: {
      default: () => import(/* webpackChunkName: "About" */ './pages/about.vue'),
      header: MainNavbar,
      footer: MainFooter,
    },
  },
  {
    path: '/green-solutions',
    name: 'GreenSolutions',
    components: {
      default: () => import(/* webpackChunkName: "GreenSolutions" */ './pages/GreenSolutions.vue'),
      header: MainNavbar,
      footer: MainFooter,
    },
  },
  {
    path: '/contact',
    name: 'ContactUs',
    components: {
      default: () => import(/* webpackChunkName: "ContactUs" */ './pages/ContactUs.vue'),
      header: MainNavbar,
      footer: MainFooter,
    },
  },
];

const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    return savedPosition;
  } else if (to.hash) {
    return { selector: to.hash };
  } else {
    return { x: 0, y: 0 };
  }
};

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior,
});

export default router;
